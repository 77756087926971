import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Image, Menu } from "antd";
import logo from "assets/images/logo.png";
import logoSmall from "assets/images/logo-small.png";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";

export default function Sidebar({ setActive, collapsed }) {
  const navigate = useNavigate();
  return (
    <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
      <div className="layout-sidebar-logo">
        <Image src={collapsed ? logoSmall : logo} width="70%" preview={false} />
      </div>
      <Menu
        theme="light"
        className="layout-sidebar-menu"
        mode="inline"
        defaultSelectedKeys={["COUNTRY"]}
        onClick={(e) => {
          setActive(e.key);
          navigate("/");
        }}
        items={[
          {
            className: "fz-14",
            key: "COUNTRY",
            icon: <UserOutlined />,
            label: "Countries",
          },
          {
            key: "ARTICLE",
            icon: <UploadOutlined />,
            label: "Articles",
          },
        ]}
      />
    </Sider>
  );
}
