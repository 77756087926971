import axios from "axios";
import { API_BASE } from "./config";

const SERVICE_BASE = API_BASE + "/destination/";

const listDestinations = () => {
  return axios.get(SERVICE_BASE + "list");
};

const listRelated = () => {
  return axios.get(SERVICE_BASE + "list-related");
};

const getDestinationById = (id) => {
  return axios.get(SERVICE_BASE + `get-by-id/${id}`);
};

const getDestinationByType = ({ type, offset = 0, limit = 10 }) => {
  return axios.get(
    SERVICE_BASE + `get-by-type?offset=${offset}&limit=${limit}&type=${type}`
  );
};

const addDestination = (data) => {
  return axios.post(SERVICE_BASE + "add", data);
};

const editDestination = (data, id) => {
  return axios.put(SERVICE_BASE + `edit/${id}`, data);
};

const deleteDestination = (id) => {
  return axios.delete(SERVICE_BASE + `delete/${id}`);
};

const addImage = (data) => {
  return axios.post(SERVICE_BASE + "add-image", data);
};

const listLanguage = () => {
  return axios.get(SERVICE_BASE + "list-language");
};

const getByTypeLanguage = (type, lang) => {
  return axios.get(
    SERVICE_BASE + `get-by-type-language?type=${type}&languageCode=${lang}`
  );
};

const DestinationService = {
  listDestinations,
  listRelated,
  getDestinationById,
  getDestinationByType,
  addDestination,
  editDestination,
  deleteDestination,
  addImage,
  listLanguage,
  getByTypeLanguage,
};

export default DestinationService;
