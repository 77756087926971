import { Button, Col, Row, Tooltip, Typography } from "antd";
import CustomSlider from "components/CustomSlider";

import parse from "html-react-parser";

export const reaplaceSlider = ({ currentData, desData, selectedLang }) => {
  const data = parse(currentData.summary, {
    replace: (domNode) => {
      if (domNode.type === "text" && domNode.data.match(/\{\{.+?\}\}/g)) {
        const currentSlider = desData.sliderDestination?.find((sliderDes) => {
          const regex = /\d+/;
          const sliderId = domNode.data.match(regex);
          return +sliderDes.key === +sliderId;
        });

        return (
          <div style={{ fontStyle: "normal" }}>
            <div style={{ marginTop: "2rem" }} key={currentSlider.id}>
              <Row>
                <Col xs={24}>
                  <Typography.Title ellipsis level={4}>
                    {currentSlider.destinationSliderTranslations.find(
                      (slider) => slider.languageCode === selectedLang
                    )?.title ||
                      currentSlider.destinationSliderTranslations.find(
                        (slider) => slider.languageCode === "en"
                      )?.title}
                  </Typography.Title>
                </Col>
                <Col xs={24}>
                  <Typography.Paragraph ellipsis={{ rows: 3 }}>
                    {currentSlider.destinationSliderTranslations.find(
                      (slider) => slider.languageCode === selectedLang
                    )?.description ||
                      currentSlider.destinationSliderTranslations.find(
                        (slider) => slider.languageCode === "en"
                      )?.description}
                  </Typography.Paragraph>
                </Col>
              </Row>

              <div
                className={currentSlider.type === "2" && "seconde-slider-type"}
              >
                <CustomSlider
                  slidesToShow={
                    currentSlider.type === "1"
                      ? 5
                      : currentSlider.type === "2"
                      ? 2
                      : 3
                  }
                  slidesToScroll={
                    currentSlider.type === "1"
                      ? 5
                      : currentSlider.type === "2"
                      ? 2
                      : 3
                  }
                  responsive={
                    currentSlider.type === "1"
                      ? responsiveSliderFirstType
                      : currentSlider.type === "2"
                      ? responsiveSliderSecondeType
                      : responsiveSliderThirdType
                  }
                >
                  {currentSlider.sliderRelateds?.map((sliderCard) => (
                    <div className="slider-card-holder">
                      <div
                        onClick={() =>
                          window.open(
                            `/preview/${sliderCard.destinationId}`,
                            "_blank"
                          )
                        }
                        key={sliderCard.id}
                        className="slider-card"
                        style={{
                          background: `url(${sliderCard.destination.image})`,
                        }}
                      >
                        <div className="slider-card-header">
                          <Tooltip
                            title={
                              sliderCard.destination?.DestinationTranslations.find(
                                (lang) => lang.languageCode === selectedLang
                              )?.name ||
                              sliderCard.destination?.DestinationTranslations.find(
                                (lang) => lang.languageCode === "en"
                              )?.name
                            }
                          >
                            <Typography.Text
                              ellipsis
                              style={{
                                textAlign:
                                  currentSlider.type === "1" && "center",
                                fontSize:
                                  currentSlider.type === "1"
                                    ? "1.1rem"
                                    : currentSlider.type === "3" && "1.8rem",
                              }}
                            >
                              {sliderCard.destination?.DestinationTranslations.find(
                                (lang) => lang.languageCode === selectedLang
                              )?.name ||
                                sliderCard.destination?.DestinationTranslations.find(
                                  (lang) => lang.languageCode === "en"
                                )?.name}
                            </Typography.Text>
                          </Tooltip>

                          {sliderCard.book_now_link && (
                            <Button
                              type="primary"
                              style={{ borderRadius: "14px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(sliderCard.book_now_link, "_blank");
                              }}
                            >
                              BookNow
                            </Button>
                          )}
                        </div>
                        <div className="slider-card-info">
                          <Typography.Paragraph
                            ellipsis={{ rows: 3 }}
                            className="gc fz-16"
                            style={{
                              lineHeight: "16px",
                              marginBottom: 0,
                            }}
                          >
                            {sliderCard.destination?.DestinationTranslations.find(
                              (lang) => lang.languageCode === selectedLang
                            )?.shortDescrip ||
                              sliderCard.destination?.DestinationTranslations.find(
                                (lang) => lang.languageCode === "en"
                              )?.shortDescrip}
                          </Typography.Paragraph>
                        </div>
                      </div>
                    </div>
                  ))}
                </CustomSlider>
              </div>
            </div>
          </div>
        );
      }
    },
  });

  return data;
};

const responsiveSliderFirstType = [
  {
    breakpoint: 1150,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 950,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },

  {
    breakpoint: 750,
    settings: {
      slidesToShow: 2.5,
      slidesToScroll: 2,
    },
  },

  {
    breakpoint: 560,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: true,
    },
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1.5,
      slidesToScroll: 1,
      dots: true,
    },
  },
  {
    breakpoint: 350,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
    },
  },
];

const responsiveSliderSecondeType = [
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
    },
  },
];

const responsiveSliderThirdType = [
  {
    breakpoint: 990,
    settings: {
      slidesToShow: 2.5,
      slidesToScroll: 2,
    },
  },

  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },

  {
    breakpoint: 680,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
    },
  },
];
