import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Tag,
  Typography,
  Divider,
  Button,
  Row,
  Col,
  Image,
  Popconfirm,
  notification,
  Modal,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExpandAltOutlined,
  EyeOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { axiosCatch } from "utils/axiosUtils";
import DestinationService from "services/destination.service";

import noImage from "assets/images/no-image.jpg";

export default function Home({ active, setActive }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [destinationCount, setDestinationCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const [citiesCount, setCitiesCount] = useState(0);
  const [citiesOffset, setCitiesOffset] = useState(0);

  console.log(citiesOffset);
  console.log(citiesCount);

  useEffect(() => {
    setActive("COUNTRY");
  }, [setActive]);

  useEffect(() => {
    setLoading(true);
    setData([]);

    (async () => {
      try {
        const res = await DestinationService.getDestinationByType({
          type: active,
          offset,
        });
        setData(res.data.data.rows);
        setDestinationCount(res.data.data.count);
      } catch (err) {
        axiosCatch(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [offset, active]);

  useEffect(() => {
    if (isModalOpen) {
      setLoading(true);

      (async () => {
        try {
          const res = await DestinationService.getDestinationByType({
            type: "CITY",
            offset: citiesOffset,
          });
          setCitiesData(res.data.data.rows);
          setCitiesCount(res.data.data.count);
        } catch (err) {
          axiosCatch(err);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setCitiesCount(0);
      setCitiesOffset(0);
      setCitiesData([]);
    }
  }, [isModalOpen, citiesOffset]);

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (data) => (
        <Image
          preview={false}
          width="80px"
          height="60px"
          src={data ? data : noImage}
          style={{ objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "DestinationTranslations",
      key: "name",
      render: (data) => (
        <Typography.Text>
          {data.find((lang) => lang.languageCode === "en")?.name || ""}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Row align="middle" wrap={false} gutter={[4, 0]}>
          <Col>
            <Popconfirm
              title="Delete the item"
              description="Are you sure to delete this item?"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              onConfirm={async () => {
                await DestinationService.deleteDestination(data.id);
                const res = await DestinationService.getDestinationByType({
                  type: data.type,
                });
                if (data.type === "CITY") {
                  setCitiesData(res.data.data.rows);
                } else {
                  setData(res.data.data.rows);
                  setDestinationCount(res.data.data.count);
                }
                notification.success({ message: "Deleted successfully ✔" });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tag
                color={"volcano"}
                key={"delete"}
                style={{ borderRadius: "50%" }}
                className="clickable"
                title="Delete"
              >
                <DeleteOutlined
                  className="center-items"
                  style={{
                    width: "10px",
                    height: "25px",
                  }}
                />
              </Tag>
            </Popconfirm>
          </Col>

          <Col>
            <Tag
              onClick={() => {
                if (data.type === "CITY") {
                  navigate(`/edit-city/${data.id}`);
                } else {
                  navigate(`/edit/${data.id}`);
                }
              }}
              color={"geekblue"}
              key={"edit"}
              style={{ borderRadius: "50%" }}
              className="clickable"
              title="Edit"
            >
              <EditOutlined
                className="center-items"
                style={{
                  width: "10px",
                  height: "25px",
                }}
              />
            </Tag>
          </Col>

          {data.type === "COUNTRY" && (
            <>
              <Col>
                <Tag
                  onClick={() => {
                    setActive("CITY");
                    navigate(`/add-city/${data.id}`);
                  }}
                  color={"green"}
                  key={"add"}
                  style={{ borderRadius: "50%" }}
                  className="clickable"
                  title="Add City"
                >
                  <PlusOutlined
                    className="center-items"
                    style={{
                      width: "10px",
                      height: "25px",
                    }}
                  />
                </Tag>
              </Col>

              <Col>
                <Tag
                  onClick={() => setIsModalOpen(true)}
                  color={"purple"}
                  key={"see"}
                  style={{ borderRadius: "50%" }}
                  className="clickable"
                  title="See Cities"
                >
                  <ExpandAltOutlined
                    className="center-items"
                    style={{
                      width: "10px",
                      height: "25px",
                    }}
                  />
                </Tag>
              </Col>
            </>
          )}
          <Col>
            <Link to={`/preview/${data.id}`}>
              <Tag
                color={"magenta"}
                key={"preview"}
                style={{ borderRadius: "50%" }}
                title="Preview Destiniation"
              >
                <EyeOutlined
                  className="center-items"
                  style={{
                    width: "10px",
                    height: "25px",
                  }}
                />
              </Tag>
            </Link>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify={"space-between"} align="middle">
        <Col>
          <Typography.Title className="fz-22">{active}</Typography.Title>
        </Col>
        <Col>
          <Button
            className="wc"
            type="primary"
            onClick={() => navigate("/add")}
          >
            Add Item
          </Button>
        </Col>
      </Row>
      <Divider style={{ margin: "6px 0" }} />
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: destinationCount,
          onChange: (page) => setOffset((page - 1) * 10),
          defaultCurrent: offset / 10 + 1,
        }}
      />

      <Modal
        title="Cities"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        bodyStyle={{ minHeight: "50vh" }}
        width="90%"
        destroyOnClose={true}
        footer={false}
      >
        <Table
          columns={columns}
          dataSource={citiesData}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 10,
            total: citiesCount,
            onChange: (page) => setCitiesOffset((page - 1) * 10),
            defaultCurrent: citiesOffset / 10 + 1,
          }}
        />
      </Modal>
    </>
  );
}
