import DashboardLayout from "components/DashboardLayout";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { Home, PreviewDestination } from "views";
import AddEditDestination from "views/Home/AddEditDestination";

export default function VverseRouter() {
  const [active, setActive] = useState("COUNTRY");

  return (
    <DashboardLayout active={active} setActive={setActive}>
      <Routes>
        <Route index element={<Home active={active} setActive={setActive} />} />
        <Route path="/add" element={<AddEditDestination type={active} />} />
        <Route path="/preview/:id" element={<PreviewDestination />} />
        <Route
          path="/edit/:id"
          element={<AddEditDestination type={active} />}
        />
        <Route
          path="/add-city/:countryId"
          element={<AddEditDestination type={"CITY"} />}
        />
        <Route
          path="/edit-city/:id"
          element={<AddEditDestination type={"CITY"} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </DashboardLayout>
  );
}
