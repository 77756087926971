import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
  Tabs,
  Typography,
  Upload,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { axiosCatch } from "utils/axiosUtils";
import DestinationService from "services/destination.service";

import noImage from "assets/images/no-image.jpg";

import "./styles.css";
import { API_BASE } from "services/config";

export default function AddEditDestination({ type }) {
  const navigate = useNavigate();
  const { id, countryId } = useParams();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [addLoading, setAddLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [sliderItems, setSliderItems] = useState([]);
  const [sliderFields, setSliderFields] = useState([]);
  const [langs, setLangs] = useState([]);
  const [activeTab, setActiveTab] = useState("en");
  const [relatedDes, setRelatedDes] = useState([]);

  const slider = Form.useWatch("slider", form);

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });
  };

  useEffect(() => {
    if (slider && slider.length > 0 && slider[0] !== undefined) {
      setSliderItems(
        slider.map((slide) => ({
          value: slide?.slider_key,
          label: slide?.slider_key,
        }))
      );
    }

    if (slider) {
      const data = form.getFieldValue(`summary_${activeTab}`);
      let summarySliders = data?.match(/\{\{.+?\}\}/g);

      let match = [-1];
      const avaliableSliders = slider.map((slide) => slide?.slider_key || "s");

      summarySliders = summarySliders?.filter((slider) => {
        const regex = /\d+/;
        match = slider.match(regex);
        return !avaliableSliders.includes(+match[0]);
      });

      summarySliders?.forEach((deletedSlider) => {
        form.setFieldValue(
          `summary_${activeTab}`,
          data.replace(deletedSlider, "")
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slider]);

  const onFinish = async (values) => {
    try {
      setAddLoading(true);
      const formData = new FormData();
      // eslint-disable-next-line array-callback-return
      let dataTranslations = langs.map((lang) => {
        if (
          values[`name_${lang.code}`] ||
          values[`short_description_${lang.code}`] ||
          values[`summary_${lang.code}`] ||
          values[`meta_title_${lang.code}`] ||
          values[`meta_description_${lang.code}`] ||
          values[`meta_tag_${lang.code}`]
        ) {
          return {
            name: values[`name_${lang.code}`] || "",
            shortDescription: values[`short_description_${lang.code}`] || "",
            summary: values[`summary_${lang.code}`] || "",
            metaTitle: values[`meta_title_${lang.code}`] || "",
            metaDescription: values[`meta_description_${lang.code}`] || "",
            metaTag: values[`meta_tag_${lang.code}`] || "",
            languageCode: lang.code,
          };
        }
      });

      dataTranslations = dataTranslations.filter(Boolean);

      formData.append("data", JSON.stringify(dataTranslations));
      values.image.file &&
        formData.append("image", values.image.file.originFileObj);
      countryId && formData.append("countryId", countryId);
      formData.append("type", type);
      values.slider?.length > 0 &&
        formData.append(
          "slider",
          JSON.stringify(
            values.slider.map((slide) => ({
              data: langs
                // eslint-disable-next-line array-callback-return
                .map((lang) => {
                  if (
                    values[`name_${lang.code}`] ||
                    values[`short_description_${lang.code}`] ||
                    values[`summary_${lang.code}`] ||
                    values[`meta_title_${lang.code}`] ||
                    values[`meta_description_${lang.code}`] ||
                    values[`meta_tag_${lang.code}`]
                  )
                    return {
                      title: slide[`slider_title_${lang.code}`] || "",
                      description:
                        slide[`slider_description_${lang.code}`] || "",
                      languageCode: lang.code,
                    };
                })
                .filter(Boolean),
              type: slide.slider_type,
              related: slide.slider_destination || [],
              key: slide.slider_key,
            }))
          )
        );

      if (id) {
        await DestinationService.editDestination(formData, id);
        notification.success({ message: `${type} Edit Successfully ✔` });
        navigate("/");
      } else {
        await DestinationService.addDestination(formData);
        notification.success({ message: "Country Added Successfully ✔" });
        navigate("/");
      }
    } catch (err) {
      axiosCatch(err);
    } finally {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      try {
        let [resLang, res] = await Promise.all([
          DestinationService.listLanguage(),
          DestinationService.listRelated(),
        ]);
        setLangs(resLang.data.data.rows);

        setRelatedDes(res.data.data.filter((des) => +des.id !== +id));
        if (id) {
          const res = await DestinationService.getDestinationById(id);
          const data = res.data.data;

          if (data.destinationData) {
            data.destinationData.DestinationTranslations.forEach((lang) => {
              form.setFieldValue(`name_${lang.languageCode}`, lang.name);

              form.setFieldValue(
                `short_description_${lang.languageCode}`,
                lang.shortDescription
              );

              form.setFieldValue(`summary_${lang.languageCode}`, lang.summary);

              form.setFieldValue(
                `meta_title_${lang.languageCode}`,
                lang.metaTitle
              );
              form.setFieldValue(
                `meta_description_${lang.languageCode}`,
                lang.metaDescription
              );
              form.setFieldValue(`meta_tag_${lang.languageCode}`, lang.metaTag);
            });

            const sliderData = data.sliderDestination.map((slide) => {
              const data = {};
              slide.destinationSliderTranslations.forEach(
                (sliderTranslation) => {
                  data[`slider_title_${sliderTranslation.languageCode}`] =
                    sliderTranslation.title;
                  data[`slider_description_${sliderTranslation.languageCode}`] =
                    sliderTranslation.description;
                }
              );
              return {
                ...data,
                slider_title: slide.destinationSliderTranslations,
                slider_description: slide.description,
                slider_destination: slide.sliderRelateds.map(
                  (realted) => realted.destinationId
                ),
                slider_key: +slide.key,
                slider_type: +slide.type,
              };
            });

            setSliderFields(sliderData);

            form.setFieldValue("slider", sliderData);

            form.setFieldValue(`image`, data.destinationData.image);
            setImageUrl(data.destinationData.image);
          }
        }
      } catch (err) {
        axiosCatch(err);
      } finally {
        setFetchLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, id]);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          setAddLoading(true);
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            fetch(`${API_BASE}/common/add-image`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.data,
                });
              })
              .catch((err) => {
                reject(err);
              })
              .finally(() => setAddLoading(false));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const items = langs.map((lang) => ({
    key: lang.code,
    label: (
      <Row align="middle" justify="center" gutter={[6, 0]} wrap={false}>
        <Col>
          <Image height={14} preview={false} alt={lang.code} src={lang.flag} />
        </Col>
        <Col>
          <Typography.Text>{lang.code.toUpperCase()}</Typography.Text>
        </Col>
      </Row>
    ),
    forceRender: true,
    children: (
      <div>
        <Form.Item
          name={`name_${lang.code}`}
          label="Name"
          rules={[
            {
              required: lang.code === "en" ? true : false,
              message: "Please Enter Name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>

        <Form.List name="slider">
          {(_, { add, remove }) => (
            <>
              {sliderFields?.map((slider, index) => (
                <Row
                  key={slider.slider_key}
                  align="middle"
                  className="mb-1 form-slider-section"
                  justify="space-between"
                >
                  <Col xs={24}>{`{{${slider.slider_key}}}`}</Col>
                  <Form.Item
                    name={[index, `slider_key`]}
                    noStyle
                    initialValue={slider.slider_key}
                  >
                    <Input style={{ display: "none" }} />
                  </Form.Item>
                  <Col xs={20}>
                    <Form.Item
                      label="Slider Title"
                      name={[index, `slider_title_${lang.code}`]}
                      rules={[
                        {
                          required: lang.code === "en" ? true : false,
                          message: "Please Enter Title",
                        },
                      ]}
                    >
                      <Input
                        placeholder="First Name"
                        size="large"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20}>
                    <Form.Item
                      name={[index, `slider_description_${lang.code}`]}
                      rules={[
                        {
                          required: lang.code === "en" ? true : false,
                          message: "Please Enter Description",
                        },
                      ]}
                      label="Slider Description"
                    >
                      <Input.TextArea placeholder="Description" size="large" />
                    </Form.Item>
                  </Col>
                  <Col style={{ height: "fit-content" }}>
                    <MinusCircleOutlined
                      onClick={() => {
                        setSliderFields((prev) =>
                          prev.filter(
                            (slide) => slide.slider_key !== slider.slider_key
                          )
                        );
                        remove(index);
                      }}
                      style={{
                        height: "fit-content",
                        fontSize: "1rem",
                        color: "red",
                      }}
                    />
                  </Col>
                  <Col xs={20}>
                    <Form.Item
                      name={[index, "slider_destination"]}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Related Destination",
                        },
                      ]}
                      label="Related Destination"
                    >
                      <Select
                        size="large"
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please Select Related Destination"
                        options={types.map((type) => ({
                          label: type.name,
                          options: relatedDes
                            ?.filter((des) => des.type === type.key)
                            ?.map((option) => ({
                              value: option.id,
                              label: <DestinationOption option={option} />,
                            })),
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20}>
                    <Form.Item
                      name={[index, "slider_type"]}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Slider Type",
                        },
                      ]}
                      label="Slider Type"
                      initialValue={1}
                    >
                      <Radio.Group>
                        <Radio value={2}>2 Cards</Radio>
                        <Radio value={3}>3 Cards</Radio>
                        <Radio value={1}>More Than 3 Cards</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    setSliderFields((prev) => [
                      ...prev,
                      {
                        slider_key: sliderFields.length
                          ? Math.max(
                              ...sliderFields?.map((o) => o.slider_key)
                            ) + 1 || 1
                          : 1,
                      },
                    ]);
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Slider
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="image"
          label="Image"
          rules={[
            {
              required: lang.code === "en" ? true : false,
              message: "Please Enter Image",
            },
          ]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={() => {}}
            accept="image/*"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: lang.code === "en" ? true : false,
              message: "Please Short Description",
            },
          ]}
          name={`short_description_${lang.code}`}
          label="Short Description"
        >
          <Input.TextArea size="large" placeholder="Enter Short Description" />
        </Form.Item>

        <Form.Item
          name={`summary_${lang.code}`}
          label="Summary"
          rules={[
            {
              required: lang.code === "en" ? true : false,
              message: "Please Enter Summary",
            },
          ]}
        >
          <CKEditor
            id={sliderItems.length + slider?.length + activeTab}
            editor={Editor}
            onReady={(editor) => {
              if (form.getFieldValue(`summary_${lang.code}`)) {
                editor.setData(form.getFieldValue(`summary_${lang.code}`));
              }
            }}
            onChange={(_, editor) => {
              const data = editor.getData();
              form.setFieldValue(`summary_${lang.code}`, data);
            }}
            config={{
              extraPlugins: [uploadPlugin],
              sliderItems: sliderItems?.filter((slide) => slide.value),
            }}
          />
        </Form.Item>

        <div id="editor"></div>

        <Form.Item name={`meta_title_${lang.code}`} label="Meta Title">
          <Input size="large" placeholder="Enter Meta Title" />
        </Form.Item>

        <Form.Item
          name={`meta_description_${lang.code}`}
          label="Meta Description"
        >
          <Input.TextArea size="large" placeholder="Enter Meta Description" />
        </Form.Item>

        <Form.Item name={`meta_tag_${lang.code}`} label="Meta Tags">
          <Input size="large" placeholder="Enter Meta Tags" />
        </Form.Item>
      </div>
    ),
  }));

  if (fetchLoading)
    return (
      <Row justify="center">
        <LoadingOutlined />
      </Row>
    );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "50vh",
      }}
    >
      <div>
        <Tabs
          type="card"
          onTabClick={(key) => setActiveTab(key)}
          defaultActiveKey={"en"}
          items={items}
        />
      </div>

      <div>
        <Form.Item style={{ marginBottom: "0" }}>
          <Row justify="end">
            <Button htmlType="submit" type="primary" loading={addLoading}>
              {id ? "Edit" : "Add"}
            </Button>
          </Row>
        </Form.Item>
      </div>
    </Form>
  );
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 5MB!");
  }
  return (isJpgOrPng && isLt2M) || false;
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

function DestinationOption({ option }) {
  const enLang = option.DestinationTranslations.find(
    (lang) => lang.languageCode === "en"
  );
  return (
    <Row align="middle" wrap={false} gutter={[4, 0]}>
      <Col>
        <Image
          preview={false}
          alt={enLang?.name}
          src={option.image || noImage}
          width={30}
          height={30}
        />
      </Col>
      <Col>
        <Typography.Text>{enLang?.name}</Typography.Text>
      </Col>
    </Row>
  );
}

const types = [
  { key: "COUNTRY", name: "Country" },
  { key: "CITY", name: "City" },
  { key: "ARTICLE", name: "Articles" },
  { key: "TOURIST_ATTRACTION", name: "Tourist Attraction" },
];
