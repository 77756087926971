import { MenuOutlined } from "@ant-design/icons";
import { Layout, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import Sidebar from "components/Sidebar";
import { useLocation } from "react-router-dom";

export default function DashboardLayout({ children, active, setActive }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [showNavAndSide, setShowNavAndSide] = useState(true);

  useEffect(() => {
    if (location.pathname.includes("preview")) {
      setShowNavAndSide(false);
    } else {
      setShowNavAndSide(true);
    }
  }, [location]);

  return (
    <Layout>
      {showNavAndSide && (
        <Sidebar
          active={active}
          setActive={setActive}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      )}
      <Layout
        className="site-layout"
        style={{
          height: "100%",
        }}
      >
        {showNavAndSide && (
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <MenuOutlined
              className="trigger fz-20"
              onClick={() => setCollapsed(!collapsed)}
              style={{ margin: "10px" }}
            />
          </Header>
        )}
        <Content
          style={{
            margin: showNavAndSide && "24px 16px",
            padding: showNavAndSide ? 24 : "1rem 0 0",
            background: colorBgContainer,
            minHeight: "88vh",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
