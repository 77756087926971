import { useEffect, useState } from "react";
import { Col, Image, Row, Select, Typography } from "antd";
import { useParams } from "react-router-dom";

import { axiosCatch } from "utils/axiosUtils";
import { reaplaceSlider } from "views/PreviewDestination/replaceSlider";
import DestinationService from "services/destination.service";

import { LoadingOutlined } from "@ant-design/icons";

import "./styles.css";

export default function PreviewDestination() {
  const { id } = useParams();
  const [desData, setDesData] = useState({});
  const [desDataTranslations, setDesDataTranslations] = useState({});
  const [langs, setLangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLang, setSelectedLang] = useState("en");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [resLang, res] = await Promise.all([
          DestinationService.listLanguage(),
          DestinationService.getDestinationById(id),
        ]);

        const avaliableLangsForDes =
          res.data.data.destinationData.DestinationTranslations.map(
            (des) => des.languageCode
          );
        setLangs(
          resLang.data.data.rows.filter((lang) =>
            avaliableLangsForDes.includes(lang.code)
          )
        );
        setDesData(res.data.data);
        setDesDataTranslations(
          res.data.data.destinationData.DestinationTranslations.find(
            (lang) => lang.languageCode === "en"
          )
        );
      } catch (err) {
        axiosCatch(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (desDataTranslations.name && desData.sliderDestination) {
      setDesDataTranslations(() => {
        const currentData = {
          ...desData.destinationData.DestinationTranslations.find(
            (lang) => lang.languageCode === selectedLang
          ),
        };

        let htmlData = reaplaceSlider({
          currentData,
          desData,
          selectedLang,
        });
        currentData.summary = htmlData;

        return { ...currentData };
      });
    }
  }, [desData, desDataTranslations.name, selectedLang]);

  const languageChange = (e) => {
    setSelectedLang(e);
    setDesDataTranslations(
      desData.destinationData.DestinationTranslations.find(
        (des) => des.languageCode === e
      )
    );
  };

  if (loading) {
    return (
      <Row style={{ height: "70vh" }} justify="center" align="middle">
        <LoadingOutlined />
      </Row>
    );
  }

  if (desDataTranslations.name) {
    return (
      <section className="container">
        <Row
          justify="space-between"
          align="middle"
          dir={selectedLang === "ar" ? "rtl" : "ltr"}
        >
          <Col>
            <Typography.Title level={2}>
              {desDataTranslations.name}
            </Typography.Title>
          </Col>
          <Col>
            <Select
              defaultValue={"en"}
              placeholder="test"
              onChange={languageChange}
              options={langs.map((lang) => ({
                value: lang.code,
                label: (
                  <Row align="middle" gutter={[6, 0]} wrap={false}>
                    <Col>
                      <Image
                        alt={lang.iso}
                        preview={false}
                        src={lang.flag}
                        width={16}
                      />
                    </Col>
                    <Col>{lang.code.toUpperCase()}</Col>
                  </Row>
                ),
              }))}
            />
          </Col>
        </Row>

        <div className="summary-preview ck-content">
          {desDataTranslations.summary}
        </div>
      </section>
    );
  }
}
